import { dateOrWeekdayFormat } from './date-formatter';
import { isInFuture } from '../../../play-core/models/Media';
import { utcToCentralEuropean } from '../../../play-core/utils/date-helper';
export const sortByDate = direction => (media1, media2) => {
  if (direction === 'asc') {
    return new Date(media2.date).getTime() - new Date(media1.date).getTime();
  }
  return new Date(media1.date).getTime() - new Date(media2.date).getTime();
};
export const dateOrWebFirstText = (date, t, thresholdFutureWeekday) => isInFuture(date) ? t('global.webFirst') : dateOrWeekdayFormat(utcToCentralEuropean(date), t, thresholdFutureWeekday);
export const formatSeason = (season, episode) => [season ? `S${season}` : '', episode ? `E${episode}` : ''].filter(item => !!item).join(' ');
export const formatTitleWithoutSeasonEpisode = title => {
  const search = / \((Staffel|Saison|Stafla|Folge|Épisode|Episode|Episoda)(.*)\)/g;
  return `${title.replace(search, '')}`;
};

/**
 * Generates a media title string that includes the episode and season number if applicable.
 *
 * @param media - The media object containing details about the media item.
 * @param businessUnit - The business unit identifier which determines the formatting of the title.
 * @param t - The translation function used to localize the season and episode information.
 * @returns The formatted media title string.
 */
export const getMediaTitleWithEpisodeAndSeasonNumber = (media, businessUnit, t) => {
  const {
    title,
    seasonNumber,
    episodeNumber
  } = media;
  const seasonEpisodeInfo = getSeasonEpisodeInfo(t, businessUnit, episodeNumber, seasonNumber);

  // If we have no season or episode information or the title already contains any of it, return the title as is
  if (seasonEpisodeInfo.length === 0 || containsSeasonOrEpisode(title, seasonEpisodeInfo)) return title;
  switch (businessUnit) {
    case 'srf':
    case 'rtr':
      return `${title} (${seasonEpisodeInfo.join(', ')})`;
    case 'rts':
      return `${title} (${seasonEpisodeInfo.join(' - ')})`;
    default:
      return title;
  }
};

/**
 * Retrieves season and episode information formatted as strings based on the business unit.
 *
 * @param t - A translation function to localize the strings.
 * @param bu - The business unit which determines the order of season and episode information.
 * @param episodeNumber - (Optional) The episode number to include in the formatted string.
 * @param seasonNumber - (Optional) The season number to include in the formatted string.
 * @returns An array of strings containing the formatted season and episode information.
 */
export const getSeasonEpisodeInfo = (t, bu, episodeNumber, seasonNumber) => {
  switch (bu) {
    case 'srf':
    case 'rtr':
      return [seasonNumber !== undefined ? `${t('global.season')} ${seasonNumber}` : null, episodeNumber !== undefined ? `${t('global.episode')} ${episodeNumber}` : null].filter(Boolean);
    case 'rts':
      return [
      // the translation key (Épisode) does not match with the rts backend (Episode)
      episodeNumber !== undefined ? `Episode ${episodeNumber}` : null, seasonNumber !== undefined ? `${t('global.season')} ${seasonNumber}` : null].filter(Boolean);
    default:
      return [];
  }
};

/**
 * Checks if the given title contains any of the season or episode information.
 *
 * @param title - The title to be checked.
 * @param seasonEpisodeInfo - An array of season or episode information to look for in the title.
 * @returns `true` if the title contains any of the season or episode information, otherwise `false`.
 */
export const containsSeasonOrEpisode = (title, seasonEpisodeInfo) => {
  const normalizedTitle = title.toLowerCase();
  const normalizedSeasonEpisodeInfo = seasonEpisodeInfo.map(info => info.toLowerCase());
  return normalizedSeasonEpisodeInfo.some(info => normalizedTitle.includes(info));
};